"use client";

import React, { useState } from "react";
import {
  useBetTokenBalance,
  useBetsSummary,
  useChain,
  useNativeBalance,
} from "@azuro-org/sdk";
import { type ChainId } from "@azuro-org/toolkit";
import { Listbox } from "@headlessui/react";
import { Message } from "@locmod/intl";
import cx from "classnames";
import { createConfig } from "wagmi";
import { useAccount } from '@azuro-org/sdk-social-aa-connector'
import { config } from "wallet";
import { constants } from "helpers";
import { useReadContract } from "wagmi";
import { erc20Abi, formatEther, http } from "viem";
import { formatToFixed } from "helpers/formatters";
import { Icon } from "components/ui";
import { Dropdown } from "components/inputs";
import { openModal } from "@locmod/modal";

import messages from "./messages";
import { GAMBI_TOKEN_ADDRESS } from "helpers/constants";
import { mainnet } from "viem/chains";

type ChainCurrencyProps = {
  className?: string;
  chainClassName?: string;
  chainId: ChainId;
  size: 4 | 5;
  borderColor?: "bg-l0" | "grey-10" | "grey-20";
  withGrayscale?: boolean;
};

const ChainCurrency: React.FC<ChainCurrencyProps> = ({
  className,
  chainClassName,
  chainId,
  size,
  withGrayscale,
}) => {
  return (
    <div className={cx("flex items-center", className)}>
      <div
        className={cx(
          "border-2 rounded-full z-10 transition-colors",
          chainClassName
        )}
      >
        <Icon className={`size-${size}`} name={constants.chainIcons[chainId]} />
      </div>
      <Icon
        className={cx("-ml-1", `size-${size}`, { grayscale: withGrayscale })}
        name={constants.currencyIcons[chainId]}
      />
    </div>
  );
};

const ChainSelect: React.FC = () => {
  const { appChain, setAppChainId } = useChain();

  return (
    <div className="border border-grey-20 p-1 rounded-md">
      <Listbox value={appChain.id} onChange={setAppChainId}>
        <Listbox.Button className="p-2 flex items-center justify-between w-full group/select">
          <div className="flex items-center">
            <ChainCurrency
              className="mr-2"
              chainClassName="border-bg-l2"
              chainId={appChain.id}
              size={4}
            />
            <div className="text-caption-13">{appChain.name}</div>
          </div>
          <Icon
            className="size-4 text-grey-60 group-hover/select:text-grey-90 transition-colors group-aria-[controls]/select:rotate-180"
            name="interface/chevron_down"
          />
        </Listbox.Button>
        <Listbox.Options className="w-full space-y-[2px]">
          {config.chains.map((chain) => {
            const isActive = appChain.id === chain.id;

            return (
              <Listbox.Option
                key={chain.id}
                value={chain.id}
                className="flex items-center justify-between p-2 cursor-pointer bg-bg-l3 first-of-type:rounded-t-sm last-of-type:rounded-b-sm"
              >
                <div className="flex items-center">
                  <ChainCurrency
                    chainId={chain.id}
                    chainClassName="border-bg-l3"
                    className="mr-2"
                    size={4}
                  />
                  <div className="text-caption-12">{chain.name}</div>
                </div>
                <div
                  className={cx(
                    "size-4 border flex items-center justify-center rounded-full",
                    {
                      "border-grey-20": !isActive,
                      "border-brand-70": isActive,
                    }
                  )}
                >
                  {isActive && (
                    <div className="size-3 bg-brand-50 rounded-full" />
                  )}
                </div>
              </Listbox.Option>
            );
          })}
        </Listbox.Options>
      </Listbox>
    </div>
  );
};

const WrapDropdown: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState<"wrap" | "unwrap" | null>(null);
  const [amount, setAmount] = useState("");

  const handleClick = (option: "wrap" | "unwrap") => {
    if (selected === option) {
      setSelected(null);
      setIsOpen(false);
    } else {
      setSelected(option);
      setIsOpen(true);
    }
  };

  return (
    <div className=" flex flex-col">
      <div className="flex flex-row gap-x-1 text-sm items-center justify-center">
        <button
          className={`${selected === "wrap" ? "underline" : "hidden"}`}
          onClick={() => handleClick("wrap")}
        >
          Wrap
        </button>
        <button
          className={`${selected === "unwrap" ? "underline" : "hidden"}`}
          onClick={() => handleClick("unwrap")}
        >
          Unwrap
        </button>
        <Icon
          className={`size-4 justify-center transition-transform ${
            isOpen ? "rotate-180" : ""
          }`}
          name="interface/chevron_down"
        />
      </div>
      {isOpen && (
        <div className="flex text-xs top-full left-0 mt-1 p-1 bg-bg-l1 rounded-md shadow-md">
          <div className="flex items-center justify-center">
            <input
              type="number"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
              className="w-[50%] p-1 text-black border border-grey-20 rounded"
              placeholder="Amount"
            />
            <span className="ml-1">{selected === "wrap" ? "CHZ" : "WCHZ"}</span>
          </div>
        </div>
      )}
    </div>
  );
};

const BalanceInfo: React.FC = () => {
  const { address } = useAccount();
  const { appChain, betToken } = useChain();
  const { balance, loading: isBalanceFetching } = useBetTokenBalance();
  const { balance: nativeBalance, loading: isNativeBalanceFetching } =
    useNativeBalance();
  const {
    inBets,
    toPayout,
    loading: isBetsSummaryFetching,
  } = useBetsSummary({
    account: address!,
  });

  const config = createConfig({
    chains: [mainnet],
    transports: {
      [mainnet.id]: http(),
    },
  });

  const { data: gambiBalance } = useReadContract({
    address: GAMBI_TOKEN_ADDRESS as `0x${string}`,
    abi: erc20Abi,
    functionName: "balanceOf",
    args: [address as `0x${string}`],
    chainId: mainnet.id,
    config,
  });

  const handleWrapUnwrap = () => {
    openModal("WrapChilizModal");
  };

  return (
    <div className="rounded-md bg-bg-l1 overflow-hidden">
      <div className="py-2 px-3 border-b border-b-bg-l2">
        <Message
          className="text-caption-13 text-grey-60 mb-[2px]"
          value={messages.balance}
        />
        <div className="space-x-1 flex items-center">
          {Boolean(isBalanceFetching || isNativeBalanceFetching) ? (
            <div className="bone h-4 w-10 rounded-full" />
          ) : (
            <>
              <span className="text-caption-13 font-semibold">
                {formatToFixed(balance || 0, 2)} {betToken.symbol}
              </span>
              <span className="text-caption-12 text-grey-60">
                + {formatToFixed(nativeBalance || 0, 2)}{" "}
                {appChain.nativeCurrency.symbol}
              </span>
              {appChain.id === 88888 && (
                <button
                  className="items-center text-caption-12 "
                  onClick={() => handleWrapUnwrap()}
                >
                  (Un)wrap
                </button>
              )}
            </>
          )}
        </div>
        <div className="text-caption-13 font-semibold ">
          {formatToFixed(formatEther(gambiBalance || 0n) || 0, 2)} GAMBI
        </div>
      </div>
      <div className="flex items-center">
        <div className="w-full py-2 px-3">
          <Message
            className="text-caption-13 text-grey-60 mb-[2px]"
            value={messages.inBets}
            tag="p"
          />
          {isBetsSummaryFetching ? (
            <div className="bone h-4 w-10 rounded-full" />
          ) : (
            <div className="text-caption-13 font-semibold">
              {formatToFixed(inBets || 0, 2)} {betToken.symbol}
            </div>
          )}
        </div>
        <div className="w-full border-l border-l-bg-l2 py-2 px-3">
          <Message
            className="text-caption-13 text-grey-60 mb-[2px]"
            value={messages.toRedeem}
            tag="p"
          />
          {isBetsSummaryFetching ? (
            <div className="bone h-4 w-10 rounded-full" />
          ) : (
            <div className="text-caption-13 font-semibold">
              {formatToFixed(toPayout || 0, 2)} {betToken.symbol}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const Content: React.FC = () => {
  return (
    <div className="border border-grey-20 p-2 ds:w-[18.75rem] bg-bg-l2 rounded-md overflow-hidden space-y-2">
      <ChainSelect />
      <BalanceInfo />
    </div>
  );
};

const Balance = () => {
  const { appChain } = useChain();
  const { balance, loading } = useBetTokenBalance();

  const rootClassName = cx(
    "wd:h-10 -wd:h-8 bg-transparent flex items-center justify-between border border-grey-15 px-3 w-fit text-grey-60 ui-open:text-grey-90 hover:text-grey-90 ui-open:bg-grey-10 hover:bg-grey-10 ui-open:border-grey-20 hover:border-grey-20 transition-all mb:w-full rounded-md"
  );

  return (
    <Dropdown
      className={cx("group")}
      contentClassName="mb:p-0"
      content={<Content />}
      placement="bottomRight"
    >
      <div className={rootClassName}>
        <div className="flex items-center">
          <ChainCurrency
            className="mr-2"
            chainClassName="border-bg-l0 group-hover:border-grey-10 ui-open:border-grey-10"
            chainId={appChain.id}
            size={5}
          />
          {loading ? (
            <div className="bone h-4 w-10 rounded-full" />
          ) : (
            <div className="text-caption-13">
              {formatToFixed(balance || 0, 2)}
            </div>
          )}
          <Icon
            className="size-4 ui-open:rotate-180"
            name="interface/caret_down"
          />
        </div>
      </div>
    </Dropdown>
  );
};

export default Balance;
